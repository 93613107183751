import React from 'react';
import { Container, Grid, Card, CardContent, Typography, CardMedia, Button, Box } from '@mui/material';
import Banner from '../compoments/Banner';

const WhatWeDo = () => {
    return (
        <>
            <Banner title="What We Do" />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 10

            }}>
                <Typography variant='h4' color='text.primary' fontSize={30} fontWeight={600} gutterBottom>What We Do</Typography>
                <Typography variant='caption' color='text.secondary' fontSize={17} sx={{ width: "600px", textAlign: 'center' }}>Capturing moments of inspiration and creativity in every frame.</Typography>
            </Box>
            <Container sx={{ marginTop: "20px", padding: "16px" }}>
                <Grid container spacing={2}>
                    {/* First Row */}
                    <Grid container item spacing={2} mb={4}>
                        {/* Health Awareness Campaigns Card */}
                        <Grid item xs={12} sm={6}>
                            <Card
                                sx={{
                                    transition: '0.3s',
                                    '&:hover': {
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                        transform: 'scale(1.02)',
                                        cursor: 'pointer'
                                    },
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    alt="Health Awareness"
                                    height="230"
                                    image="https://via.placeholder.com/400x200" // Replace with actual image
                                />
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>Health Awareness Campaigns</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        We run campaigns to educate people about the importance of healthy eating, the dangers of addiction, and the benefits of regular health check-ups. Our goal is to spread knowledge that empowers individuals to make better health choices.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Community Programs Card */}
                        <Grid item xs={12} sm={6}>
                            <Card
                                sx={{
                                    transition: '0.3s',
                                    '&:hover': {
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                        transform: 'scale(1.02)',
                                        cursor: 'pointer'

                                    },
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    alt="Community Programs"
                                    height="230"
                                    image="https://via.placeholder.com/400x200" // Replace with actual image
                                />
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>Community Programs</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Our community programs are designed to address specific health needs in both urban and rural areas. From nutrition workshops to addiction support groups, we offer practical solutions and support tailored to the needs of different communities.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    {/* Second Row */}
                    <Grid container item spacing={2} mb={4}>
                        {/* National and International Outreach Card */}
                        <Grid item xs={12} sm={6}>
                            <Card
                                sx={{
                                    transition: '0.3s',
                                    '&:hover': {
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                        transform: 'scale(1.02)',
                                        cursor: 'pointer'

                                    },
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    alt="National and International Outreach"
                                    height="230"
                                    image="https://via.placeholder.com/400x200" // Replace with actual image
                                />
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>National and International Outreach</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        We collaborate with various organizations and stakeholders across India and abroad to expand our reach and impact. By sharing resources and expertise, we work together to tackle global health challenges.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Research and Advocacy Card */}
                        <Grid item xs={12} sm={6}>
                            <Card
                                sx={{
                                    transition: '0.3s',
                                    '&:hover': {
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                        transform: 'scale(1.02)',
                                        cursor: 'pointer'

                                    },
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    alt="Research and Advocacy"
                                    height="230"
                                    image="https://via.placeholder.com/400x200" // Replace with actual image
                                />
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>Research and Advocacy</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Through ongoing research and advocacy, we strive to identify and address emerging health issues. Our efforts help shape policies and practices that promote better health outcomes for all.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Button variant="contained" sx={{ marginTop: 2, display: 'block', mx: 'auto' }}>Get Involved</Button>
            </Container>
        </>
    );
}

export default WhatWeDo;
