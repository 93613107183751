import React, { useState } from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, CardMedia, Button, Pagination } from '@mui/material';
import Banner from '../compoments/Banner';

// Updated blog data with additional posts
const blogs = [
    { id: 1, title: 'Blog Post 1', image: 'https://via.placeholder.com/300', description: 'This is a short description of Blog Post 1.' },
    { id: 2, title: 'Blog Post 2', image: 'https://via.placeholder.com/300', description: 'This is a short description of Blog Post 2.' },
    { id: 3, title: 'Blog Post 3', image: 'https://via.placeholder.com/300', description: 'This is a short description of Blog Post 3.' },
    { id: 4, title: 'Blog Post 4', image: 'https://via.placeholder.com/300', description: 'This is a short description of Blog Post 4.' },
    { id: 5, title: 'Blog Post 5', image: 'https://via.placeholder.com/300', description: 'This is a short description of Blog Post 5.' },
    { id: 6, title: 'Blog Post 6', image: 'https://via.placeholder.com/300', description: 'This is a short description of Blog Post 6.' },
    { id: 7, title: 'Blog Post 7', image: 'https://via.placeholder.com/300', description: 'This is a short description of Blog Post 7.' },
    { id: 8, title: 'Blog Post 8', image: 'https://via.placeholder.com/300', description: 'This is a short description of Blog Post 8.' },
    { id: 9, title: 'Blog Post 9', image: 'https://via.placeholder.com/300', description: 'This is a short description of Blog Post 9.' },
    { id: 10, title: 'Blog Post 10', image: 'https://via.placeholder.com/300', description: 'This is a short description of Blog Post 10.' },
    { id: 11, title: 'Blog Post 11', image: 'https://via.placeholder.com/300', description: 'This is a short description of Blog Post 11.' },
    { id: 12, title: 'Blog Post 12', image: 'https://via.placeholder.com/300', description: 'This is a short description of Blog Post 12.' },
];

const Blog = () => {
    const [page, setPage] = useState(1);
    const postsPerPage = 6; // Show 6 posts per page

    // Calculate current posts based on the current page
    const indexOfLastPost = page * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = blogs.slice(indexOfFirstPost, indexOfLastPost);

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <Banner title="Blog" />
            <Container sx={{ mt: 8, mb: 5 }}>
                <Grid container spacing={4}>
                    {currentPosts.map((blog) => (
                        <Grid item xs={12} md={4} key={blog.id}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    alt={blog.title}
                                    height="220"
                                    image={blog.image}
                                />
                                <CardContent>
                                    <Typography variant="h6">{blog.title}</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {blog.description}
                                    </Typography>
                                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button color="primary">Read More</Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Pagination
                        count={Math.ceil(blogs.length / postsPerPage)}
                        page={page}
                        onChange={handleChange}
                        color="primary"
                    />
                </Box>
            </Container>
        </>
    );
};

export default Blog;
