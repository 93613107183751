import * as React from 'react';
import { Box, ImageList, ImageListItem, Typography } from '@mui/material';
import Banner from '../compoments/Banner';


const itemData = [
    {
        img: 'https://images.unsplash.com/photo-1549388604-817d15aa0110',
        title: 'Bed',
    },
    {
        img: 'https://images.unsplash.com/photo-1525097487452-6278ff080c31',
        title: 'Books',
    },
    {
        img: 'https://images.unsplash.com/photo-1523413651479-597eb2da0ad6',
        title: 'Sink',
    },
    {
        img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
        title: 'Kitchen',
    },
    {
        img: 'https://images.unsplash.com/photo-1588436706487-9d55d73a39e3',
        title: 'Blinds',
    },
    {
        img: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622',
        title: 'Chairs',
    },
    {
        img: 'https://images.unsplash.com/photo-1530731141654-5993c3016c77',
        title: 'Laptop',
    },
    {
        img: 'https://images.unsplash.com/photo-1481277542470-605612bd2d61',
        title: 'Doors',
    },
    {
        img: 'https://images.unsplash.com/photo-1517487881594-2787fef5ebf7',
        title: 'Coffee',
    },
    {
        img: 'https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee',
        title: 'Storage',
    },
    {
        img: 'https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62',
        title: 'Candle',
    },
    {
        img: 'https://images.unsplash.com/photo-1519710164239-da123dc03ef4',
        title: 'Coffee table',
    },
];

const PhotoGallery = () => {
    return (
        <>
            <Banner title="Gallery" />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 10

            }}>
                <Typography variant='h4' color='text.primary' fontSize={35} fontWeight={600} gutterBottom>Gallery</Typography>
                <Typography variant='caption' color='text.secondary' fontSize={18} sx={{ width: "600px", textAlign: 'center' }}>Capturing moments of inspiration and creativity in every frame.</Typography>
            </Box>
            <Box sx={{ width: '100%', height: "100%", overflowY: 'scroll', p: 3, mt: 3, mb: 10 }}>
                <ImageList variant="masonry" cols={3} gap={8}>
                    {itemData.map((item) => (
                        <ImageListItem key={item.img}>
                            <img
                                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                src={`${item.img}?w=248&fit=crop&auto=format`}
                                alt={item.title}
                                loading="lazy"
                                style={{
                                    borderRadius: '10px',
                                    display: 'block',
                                    width: '100%',
                                    height: 'auto',
                                    cursor: 'pointer',
                                    transition: 'transform 0.3s ease',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    '&:hover': {
                                        transform: 'scale(1.01)',
                                    },
                                }}
                                onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.01)'}
                                onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
        </>

    );
};

export default PhotoGallery;
