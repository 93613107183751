import React from 'react';
import { Breadcrumbs, Link, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import BannerImage from '../assets/banner1.jpg'


const BackgroundContainer = styled(Box)({
    backgroundImage: `url(${BannerImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '230px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    color: '#fff',
    padding: "40px",
    position: "relative"
});

const Overlay = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
});



const Banner = ({ title }) => {
    return (
        <BackgroundContainer>
            <Overlay />
            <Typography variant="h5" sx={{ zIndex: 1, fontWeight: 600 }}>{title}</Typography>
            <Box sx={{ zIndex: 1 }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" sx={{ color: '#fff', fontSize: "17px", fontWeight: 600, cursor: "pointer" }} href="/">Home</Link>
                    <Typography sx={{ color: '#fff', fontSize: "17px", fontWeight: 600, cursor: "pointer" }}>{title}</Typography>
                </Breadcrumbs>
            </Box>
        </BackgroundContainer>
    )
}

export default Banner