// import React from 'react';
// import { Container, Typography, Box, Divider, Button, Grid, Card, CardContent, CardMedia } from '@mui/material';
// import Slider from 'react-slick';
// import testimonial1 from '../assets/Home1.jpg';
// import testimonial2 from '../assets/Home2.jpg';
// import testimonial3 from '../assets/Home3.jpg';
// import companyImage from '../assets/Home4.jpg';

// const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
// };

// const Home = () => {
//     return (
//         <Container sx={{ marginTop: '80px', padding: '16px' }}>
//             <Box sx={{ mb: 4 }}>
//                 <Slider {...sliderSettings}>
//                     <div>
//                         <img src={testimonial1} alt="Slider Image 1" style={{ width: '100%', borderRadius: '8px' }} />
//                     </div>
//                     <div>
//                         <img src={testimonial2} alt="Slider Image 2" style={{ width: '100%', borderRadius: '8px' }} />
//                     </div>
//                     <div>
//                         <img src={testimonial3} alt="Slider Image 3" style={{ width: '100%', borderRadius: '8px' }} />
//                     </div>
//                 </Slider>
//             </Box>
//             <Box sx={{ mb: 4, mt: 10 }}>
//                 <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 600, color: 'text.primary' }}>What Our Beneficiaries Say</Typography>
//                 <Grid container spacing={4}>
//                     <Grid item xs={12} md={4}>
//                         <Card>
//                             <CardMedia
//                                 component="img"
//                                 alt="Testimonial 1"
//                                 height="140"
//                                 image={testimonial1}
//                             />
//                             <CardContent>
//                                 <Typography variant="h6">John Doe</Typography>
//                                 <Typography variant="body2" color="textSecondary">
//                                     "Arogyadoot has significantly improved the health of our community. The programs are practical and impactful."
//                                 </Typography>
//                             </CardContent>
//                         </Card>
//                     </Grid>
//                     <Grid item xs={12} md={4}>
//                         <Card>
//                             <CardMedia
//                                 component="img"
//                                 alt="Testimonial 2"
//                                 height="140"
//                                 image={testimonial2}
//                             />
//                             <CardContent>
//                                 <Typography variant="h6">Jane Smith</Typography>
//                                 <Typography variant="body2" color="textSecondary">
//                                     "The awareness campaigns are excellent. They provide valuable information that helps us make better health choices."
//                                 </Typography>
//                             </CardContent>
//                         </Card>
//                     </Grid>
//                     <Grid item xs={12} md={4}>
//                         <Card>
//                             <CardMedia
//                                 component="img"
//                                 alt="Testimonial 3"
//                                 height="140"
//                                 image={testimonial3}
//                             />
//                             <CardContent>
//                                 <Typography variant="h6">Alice Johnson</Typography>
//                                 <Typography variant="body2" color="textSecondary">
//                                     "The support from Arogyadoot has been incredible. The community programs have been a great help to many families."
//                                 </Typography>
//                             </CardContent>
//                         </Card>
//                     </Grid>
//                 </Grid>
//             </Box>

//             {/* Company Information Section */}
//             <Box sx={{ textAlign: 'center' }}>
//                 <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, color: 'text.primary' }}>About Us</Typography>
//                 <Grid container spacing={4} alignItems="center">
//                     <Grid item xs={12} md={6}>
//                         <img src={companyImage} alt="Company" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                         <Typography variant="body1" paragraph>
//                             Arogyadoot Welfare Foundation is a Delhi-based social organization dedicated to improving public health through various initiatives. Our focus is on addressing health issues affecting both rural and urban populations, and we strive to create a healthier society through education, outreach, and support.
//                         </Typography>
//                         <Divider sx={{ my: 2 }} />
//                         <Button variant="contained" color="primary">Learn More</Button>
//                     </Grid>
//                 </Grid>
//             </Box>
//         </Container>
//     );
// }

// export default Home;



import React from 'react';
import { Container, Typography, Box, Grid, Button, Card, CardContent, CardMedia, Divider } from '@mui/material';
import { styled } from '@mui/system';
import heroImage from '../assets/Home1.jpg'; // Example image, replace with your own
import feature1 from '../assets/Home2.jpg'; // Example image, replace with your own
import feature2 from '../assets/Home3.jpg'; // Example image, replace with your own
import feature3 from '../assets/Home4.jpg'; // Example image, replace with your own
import testimonialImage from '../assets/Home1.jpg'; // Example image, replace with your own

const HeroSection = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    padding: theme.spacing(8, 2),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
    borderRadius: '8px',
}));

const FeatureCard = styled(Card)(({ theme }) => ({
    textAlign: 'center',
    borderRadius: '8px',
    boxShadow: theme.shadows[2],
}));

const Home = () => {
    return (
        <>
            <HeroSection>
                <Typography variant="h3" component="h1" gutterBottom>
                    Welcome to Arogyadoot Welfare Foundation
                </Typography>
                <Typography variant="h6" paragraph>
                    Making a Difference in Health Through Innovative Programs and Community Support
                </Typography>
            </HeroSection>

            <Container sx={{ mt: 8 }}>
                <Typography variant="h4" align="center" gutterBottom>
                    Our Features
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <FeatureCard>
                            <CardMedia
                                component="img"
                                alt="Feature 1"
                                height="140"
                                image={feature1}
                            />
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Community Health Programs
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    We provide various health programs to improve the well-being of our community members.
                                </Typography>
                            </CardContent>
                        </FeatureCard>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FeatureCard>
                            <CardMedia
                                component="img"
                                alt="Feature 2"
                                height="140"
                                image={feature2}
                            />
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Health Education
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    We offer educational resources to help individuals make informed health decisions.
                                </Typography>
                            </CardContent>
                        </FeatureCard>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FeatureCard>
                            <CardMedia
                                component="img"
                                alt="Feature 3"
                                height="140"
                                image={feature3}
                            />
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Support and Counseling
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Our support services include counseling and guidance to address various health concerns.
                                </Typography>
                            </CardContent>
                        </FeatureCard>
                    </Grid>
                </Grid>

                <Divider sx={{ my: 8 }} />

                <Box sx={{
                    bgcolor: '#223645',
                    color: '#fff',
                    py: 4,
                    textAlign: 'center',
                    borderRadius: '8px',
                    mb: 5
                }}>
                    <Typography variant='h6' fontWeight="600">Join Us in Making a Difference</Typography>
                    <Button variant='contained' sx={{ mt: 2 }}>Get Involved </Button>
                </Box>
            </Container>
        </>
    );
};

export default Home;
