
import React from 'react';
import { Container, Grid, Typography, Button, Card, CardContent, CardMedia } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import applicationImage from '../assets/howto1.jpg';
import examImage from '../assets/howto2.jpg';
import trainingImage from '../assets/howto3.jpg';
import Banner from './Banner';

const timelineSteps = [
    { label: 'Fill Out Application Form', date: 'By 30th October 2024', description: 'Fill out the application form on our website and make the payment via demand draft (DD), UPI, or scan code.' },
    { label: 'Online Exam', date: 'February 2025', description: 'An online exam will be conducted for ‘Arogyadoot’ candidates. Candidates who pass the exam will be selected for training.' },
    { label: 'Training', date: 'Following the exam', description: 'Candidates who pass the exam will receive training to serve the citizens of their respective villages.' },
    { label: 'Deployment', date: 'After Training', description: 'Deployed to serve the citizens of their respective villages. A monthly stipend of ₹9,500 will be provided.' }
];

const HowToApply = () => {
    return (
        <>
            <Banner title="How to Apply" />
            <Container sx={{ marginTop: "80px", padding: "16px" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: '600' }}>Application Process for Arogyadoot</Typography>
                                <Timeline position="alternate">
                                    {timelineSteps.map((step, index) => (
                                        <TimelineItem key={index}>
                                            <TimelineSeparator>
                                                <TimelineDot color="primary" />
                                                {index < timelineSteps.length - 1 && <TimelineConnector />}
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Typography variant="h6" gutterBottom>{step.label}</Typography>
                                                <Typography variant="body2" color="textSecondary">{step.date}</Typography>
                                                <Typography variant="body1" paragraph>{step.description}</Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    ))}
                                </Timeline>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardMedia
                                component="img"
                                alt="Important Dates"
                                height="300"
                                width="100%"
                                image={examImage}
                            />
                            <CardContent>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: '600' }}>Important Dates</Typography>
                                <Typography variant="body1" paragraph>
                                    - Online examination for 'Arogyadoot' candidates: January 2025<br />
                                    - Training and deployment for selected candidates: Following the exam<br />
                                    - Monthly stipend for selected candidates: ₹9,500<br />
                                    - Last date for submitting application forms and demand draft (DD) or making online payment: 30th October 2024
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardMedia
                                component="img"
                                alt="Join Us"
                                height="300"
                                width="100%"
                                image={trainingImage}
                            />
                            <CardContent>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: '600' }}>Join Us in Our Mission</Typography>
                                <Typography variant="body1" paragraph>
                                    Apply now to become an Arogyadoot and join us in our mission to create a healthier society. Please send the completed application forms and proof of payments to our mail ID or office address by the specified deadline.
                                </Typography>
                                <Button variant="contained" href="/contact">Contact Us</Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default HowToApply;

