import React from 'react'
import { BounceLoader } from 'react-spinners'
import { Container } from '@mui/material'

const Loading = () => {
    return (
        <Container
            sx={{
                width: "100%",
                height: "100vh",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <BounceLoader
                size={45}
                color={'#e12454'}
                speedMultiplier={2}

            />
        </Container>
    )
}

export default Loading