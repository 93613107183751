import React, { useState } from 'react';
import { Container, Button, Box, Typography, Divider, Grid, Modal, TextField } from "@mui/material";
import Banner from '../compoments/Banner';
import career1 from '../assets/career1.jpg';

const Career = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Banner title="Careers" />
            <Container sx={{
                marginTop: "80px",
                marginBottom: '100px',
                padding: "16px",
                backgroundColor: '#ebf5f5',
                borderRadius: '8px',
            }}>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Box>
                            <img src={career1} alt="Career" style={{ width: "100%", borderRadius: '8px' }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ paddingLeft: { xs: '0', md: '16px' } }}>
                            <Typography variant="h6" gutterBottom>Arogyadoot</Typography>
                            <Typography variant="body1" gutterBottom>
                                Join our team as an Arogyadoot and make a significant impact on the health of patients in our village centers. You will be responsible for providing health advice, spreading awareness, and assisting in our health initiatives.
                            </Typography>

                            <Divider sx={{ marginY: 2 }} />

                            <Typography variant="h6" gutterBottom>Eligibility</Typography>
                            <Typography variant="body1">• Age: 18-30 years</Typography>
                            <Typography variant="body1">• Minimum 10th pass</Typography>
                            <Typography variant="body1">• Good communication skills</Typography>
                            <Typography variant="body1">• One male and one female candidate from each village</Typography>

                            <Divider sx={{ marginY: 2 }} />

                            <Typography variant="h6" gutterBottom>Benefits</Typography>
                            <Typography variant="body1">• Opportunity to make a real difference in the community</Typography>
                            <Typography variant="body1">• Training and support provided</Typography>
                            <Typography variant="body1">• Competitive compensation</Typography>
                            <Typography variant="body1">• Flexible working hours</Typography>

                            <Box sx={{ marginTop: 3 }}>
                                <Button variant='contained' size="large" onClick={handleOpen}>Apply Now</Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Box sx={{ bgcolor: '#223645', color: '#fff', width: '100%', height: '80px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingX: 20 }}>
                <Typography variant='h6' fontWeight="600">Start your career today !</Typography>
                <Button variant='contained' onClick={handleOpen}>Contact Now</Button>
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: '500px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2
                }}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Apply for the Position
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        sx={{ mt: 2 }}
                    >
                        <TextField
                            required
                            fullWidth
                            label="Name"
                            margin="normal"
                            size='small'
                        />
                        <TextField
                            required
                            fullWidth
                            label="Mobile"
                            size='small'
                            margin="normal"
                        />
                        <TextField
                            required
                            fullWidth
                            label="Email ID"
                            type="email"
                            margin="normal"
                            size='small'

                        />
                        <TextField
                            required
                            fullWidth
                            label="Village"
                            margin="normal"
                            size='small'
                        />
                        <TextField
                            required
                            fullWidth
                            label="City"
                            margin="normal"
                            size='small'

                        />
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="contained" onClick={handleClose}>Submit</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default Career;
