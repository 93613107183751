import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Container, useMediaQuery, useTheme, Box, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../assets/logo.png';
import { Link } from 'react-router-dom'

const Header = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: '#fff', color: '#000' }}>
            <Container>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    {/* Logo */}
                    <IconButton edge="start" color="inherit" aria-label="logo" sx={{ mr: 2 }}>
                        <img src={Logo} alt="Logo" style={{ height: '50px' }} />
                    </IconButton>

                    {/* Menu for large screens */}
                    {!isMobile && (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Link to="/">
                                <Button >Home</Button>
                            </Link>
                            <Link to="/about">
                                <Button>About Us</Button>
                            </Link>
                            <Link to='/blog'>
                                <Button>Blog</Button>
                            </Link>
                            <Link to='/photos'>
                                <Button>Gallery</Button>
                            </Link>
                            <Link to='/careers'>
                                <Button >Carrers</Button>
                            </Link>

                            <Link to='/howto'>
                                <Button>How to Apply</Button>
                            </Link>
                            <Link to='/whatwedo'>
                                <Button>What we Do</Button>
                            </Link>

                            <Link to='/contact'>
                                <Button >Contact</Button>
                            </Link>



                        </Box>
                    )}

                    {/* Menu icon for small screens */}
                    {isMobile && (
                        <div>
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="menu"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenuClick}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem onClick={handleMenuClose}>Home</MenuItem>
                                <MenuItem onClick={handleMenuClose}>About Us</MenuItem>
                                <MenuItem onClick={handleMenuClose}>Services</MenuItem>
                                <MenuItem onClick={handleMenuClose}>Contact</MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;
