import React from "react";
import { Grid, Typography, TextField, IconButton, Box, Divider } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SendIcon from '@mui/icons-material/Send';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import InstagramIcon from '@mui/icons-material/Instagram';
import Logo from '../assets/logo.png';

const Footer = () => {
    return (
        <Box sx={{ backgroundColor: "#223645", color: '#ffff', padding: "40px 20px" }}>
            <Grid container spacing={4} justifyContent="space-between">
                {/* Contact Section */}
                <Grid item xs={12} md={4}>
                    <IconButton edge="start" color="inherit" aria-label="logo" sx={{ mb: 1 }} disabled>
                        <img src={Logo} alt="Logo" style={{ height: '75px' }} />
                    </IconButton>
                    <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                        Arogyadoot Welfare Foundation, based in Delhi, works to promote better health and well-being through various initiatives across India and abroad.
                    </Typography>
                    <Typography variant="body2" display="flex" alignItems="center" gutterBottom>
                        <EmailIcon sx={{ mr: 1 }} /> example@thememove.com
                    </Typography>
                    <Typography variant="body2" display="flex" alignItems="center" gutterBottom>
                        <PhoneIcon sx={{ mr: 1 }} /> (+00) 888.666.88
                    </Typography>
                    <Typography variant="body2" display="flex" alignItems="center">
                        <LocationOnIcon sx={{ mr: 1 }} /> 14 Tottenham Court Road, London, England.
                    </Typography>
                </Grid>

                {/* Useful Links */}
                <Grid item xs={12} md={4} sx={{ mt: 5 }}>
                    <Typography variant="h6" gutterBottom>
                        Useful Links
                    </Typography>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="body2" sx={{ mb: 2, cursor: 'pointer' }} >Home</Typography>
                            <Typography variant="body2" sx={{ mb: 2, cursor: 'pointer' }} >Blog</Typography>
                            <Typography variant="body2" sx={{ mb: 2, cursor: 'pointer' }} >Contact Us</Typography>
                            <Typography variant="body2" sx={{ mb: 2, cursor: 'pointer' }} >Caregivers</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" sx={{ mb: 2, cursor: 'pointer' }}>About Us</Typography>
                            <Typography variant="body2" sx={{ mb: 2, cursor: 'pointer' }}>FAQ</Typography>
                            <Typography variant="body2" sx={{ mb: 2, cursor: 'pointer' }}>Locations</Typography>
                            <Typography variant="body2" sx={{ mb: 2, cursor: 'pointer' }}>New & Blog</Typography>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item xs={12} md={4} sx={{ mt: 5 }}>
                    <Typography variant="h6" gutterBottom>
                        Subscribe
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ mb: 3 }}>
                        Get the latest updates and offers.
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <TextField
                            label="Enter your email"
                            variant="outlined"
                            size="small"
                            sx={{ flexGrow: 1 }}
                        />
                        <IconButton sx={{ marginLeft: 2 }} color="primary">
                            <SendIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ marginTop: 2 }}>
                        <IconButton>
                            <XIcon />
                        </IconButton>
                        <IconButton>
                            <FacebookIcon />
                        </IconButton>
                        <IconButton>
                            <GoogleIcon />
                        </IconButton>
                        <IconButton>
                            <InstagramIcon />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ mt: 6 }} />
            <Box sx={{ textAlign: 'center', pt: 3 }}>
                <Typography variant="body2" sx={{ mt: 1, fontSize: '15px' }}>
                    © {new Date().getFullYear()} All Rights Reserved. Designed and Developed by Shree Digital - Digital Marketing Agency.
                </Typography>
            </Box>
        </Box>
    );
};

export default Footer;
