import React from 'react';
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import Banner from '../compoments/Banner';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';


const FaqContainer = styled(Box)({
    textAlign: 'center',
    padding: '50px 20px',
});


const StyledAccordionSummary = styled(AccordionSummary)({
    backgroundColor: '#122740', // Dark blue background
    color: '#fff',
    fontWeight: 'bold',
    padding: '2px 20px',
});

const QuestionNumber = styled('span')({
    color: '#E30043', // Red color for the numbers
    marginRight: '10px',
    fontSize: '20px',
});

const Faq = () => {
    return (
        <>
            <Banner title="Frequent Questions" />
            <FaqContainer>
                <AttachEmailIcon fontSize='large' sx={{ color: "text.primary" }} />
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 6

                }}>
                    <Typography variant='h4' color='text.primary' fontSize={30} fontWeight={600} gutterBottom>Frequent Questions</Typography>
                    <Typography variant='caption' color='text.secondary' fontSize={16} sx={{ width: "600px", textAlign: 'center' }}> We offer reasonable pricing health care plans, insurance packages based
                        on financial conditions of clients..</Typography>
                </Box>
                <Accordion>
                    <StyledAccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                    >
                        <QuestionNumber>01.</QuestionNumber>
                        How do I get health care when I need it?
                    </StyledAccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            You can visit HealSoul Health Services during office hours.
                            Appointments are appreciated, but walk-ins are welcome as well. If
                            possible, please make a call ahead before visiting. This will allow
                            us to arrange services and reduce your waiting time. Hours are
                            Monday through Friday, 9:00 a.m. to 4:30 p.m.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <StyledAccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                    >
                        <QuestionNumber>02.</QuestionNumber>
                        Is there coverage for the elderly and juveniles?
                    </StyledAccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Yes, we provide coverage for the elderly and juveniles. Our health
                            care plans include specific services tailored to their needs to
                            ensure they receive the best care possible.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <StyledAccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                    >
                        <QuestionNumber>03.</QuestionNumber>
                        What if I require lab tests to be performed?
                    </StyledAccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            HealSoul Health Services provide patients with choices to ask for the conducting and analyzing of several lab tests on-site at no cost for prioritized patients or at 70% for people with an insurance. Additional testing can be ordered off site; those costs are the responsibility of the enquirers.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <StyledAccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                    >
                        <QuestionNumber>04.</QuestionNumber>
                        Are there Family Planning Services available?
                    </StyledAccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Yes. Reproductive services are always available at HealSoul Health Services. Additionally, we also have an agreement in which all enrolled clients will only have to pay a nominal fee for the first visit for checkups. They will be exempt from this fee from their second visit.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </FaqContainer>


        </>

    );
};

export default Faq;
