import React from 'react';
import { Container, Grid, Typography, Box, Divider } from '@mui/material';
import Slider from 'react-slick';
import Banner from '../compoments/Banner';
import image1 from '../assets/Home1.jpg';
import image2 from '../assets/Home1.jpg';
import image3 from '../assets/Home1.jpg';

const testimonials = [
    {
        name: "John Doe",
        testimonial: "Arogyadoot Welfare Foundation has been a beacon of hope for our community. Their health programs have made a significant impact on our lives.",
        image: "https://via.placeholder.com/150"
    },
    {
        name: "Jane Smith",
        testimonial: "The awareness campaigns run by Arogyadoot have educated me and my family on crucial health issues. I am grateful for their support and resources.",
        image: "https://via.placeholder.com/150"
    },
    {
        name: "Raj Patel",
        testimonial: "Thanks to Arogyadoot's community programs, we have access to much-needed health resources and support in our rural area. Their work is invaluable.",
        image: "https://via.placeholder.com/150"
    }
];

const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const About = () => {
    return (
        <>
            <Banner title="About" />

            <Container sx={{ marginTop: "80px", padding: "16px" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <img src={image1} alt="Arogyadoot Welfare Foundation" style={{ width: '100%', borderRadius: '8px' }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" gutterBottom align="left">Arogyadoot Welfare Foundation</Typography>
                        <Typography variant="body1" paragraph>
                            'Arogyadoot Welfare Foundation' is a Delhi-based social organization working towards creating a healthier society through various initiatives across India and abroad. We believe that health is the greatest wealth, and we focus on addressing health issues affecting rural and urban citizens.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Our National Survey reveals that citizens suffer from various diseases due to poor diet and addiction. If this trend continues, every household will have a cancer patient in the next thirty years. However, by creating awareness and working on this now, we can significantly reduce this risk.
                        </Typography>
                    </Grid>
                </Grid>

                {/* Second Row */}
                <Grid container spacing={4} sx={{ marginTop: '40px' }}>
                    <Grid item xs={12} md={6} sx={{ mt: 3 }}>
                        <Typography variant="h6" gutterBottom>Vision</Typography>
                        <Typography variant="body1" paragraph>
                            "At Arogyadoot Welfare Foundation, our vision is to create a healthier and more informed society through comprehensive health initiatives and programs. We aspire to be a leading force in public health, ensuring that individuals in both urban and rural areas have access to the necessary resources and education to lead healthier lives."
                        </Typography>
                        <Typography variant="body1" paragraph>
                            "Our vision encompasses a multi-faceted approach to health improvement, including preventative care, education on healthy lifestyles, and advocacy for better health policies. We aim to bridge the gap between healthcare access and education, making sure that everyone, regardless of their location or socioeconomic status, benefits from our programs."
                        </Typography>

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src={image2} alt="Vision Statement" style={{ width: '100%', borderRadius: '8px' }} />
                    </Grid>
                </Grid>

                {/* Third Row */}
                <Grid container spacing={4} sx={{ marginTop: '40px', mb: 10 }}>
                    <Grid item xs={12} md={6}>
                        <img src={image3} alt="Mission Statement" style={{ width: '100%', borderRadius: '8px' }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>Mission</Typography>
                        <Typography variant="body1" paragraph>
                            "At Arogyadoot Welfare Foundation, our mission is dedicated to addressing and mitigating critical health issues through a combination of practical programs, community engagement, and education. We are committed to improving public health by focusing on several key areas."
                        </Typography>
                        <Typography variant="body1" paragraph>
                            "We advocate for healthy eating practices by developing nutrition programs and providing resources that educate individuals about balanced diets and healthy lifestyle choices. Our initiatives aim to combat the rising prevalence of diet-related diseases and promote long-term health."
                        </Typography>

                    </Grid>
                </Grid>

                <Divider sx={{ marginY: 10 }} />
                <Typography variant="h5" gutterBottom align="center">What Our Beneficiaries Say</Typography>

                <Slider {...sliderSettings}>
                    {testimonials.map((testimonial, index) => (

                        <Box key={index} sx={{ padding: 2, textAlign: 'center' }}>
                            <Typography variant="body1" sx={{ width: "560px", ml: 36, mb: 2 }}>
                                "{testimonial.testimonial}"
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                - {testimonial.name}
                            </Typography>
                        </Box>
                    ))}
                </Slider>
                <Divider sx={{ marginY: 7 }} />
                <Typography variant="body1" align="center">
                    <Box sx={{ fontStyle: 'italic' }}>Join us in our mission to build a healthier society.</Box>
                </Typography>
            </Container>
        </>
    );
}

export default About;
