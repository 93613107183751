import React from 'react'
import { Container, Typography, Box, Grid, TextField, Button } from '@mui/material'

const Contact = () => {
    return (
        <>
            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2672.3569100039026!2d75.55566797343282!3d21.000266788760126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd9a75439a527f3%3A0xada5cb8d8f2ab010!2stechnoweit!5e1!3m2!1sen!2sin!4v1725901242321!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                />
            </div>
            <Container maxWidth="xl">
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 10

                }}>
                    <Typography variant='h4' color='text.primary' fontSize={30} fontWeight={600} gutterBottom>Call Us or Fill The Form</Typography>
                    <Typography variant='caption' color='text.secondary' fontSize={17} sx={{ width: "600px", textAlign: 'center' }}>Subscribe to our newsletters, call us to book a meetup or send us emails to request for service consultation.</Typography>
                </Box>

                <Box sx={{ flexGrow: 1, p: 3, mt: 6, mb: 10 }}>
                    <Grid container spacing={3} justifyContent="center">
                        <Grid item xs={12} md={4}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Box>
                                    <Typography variant="h6">LOCATION</Typography>
                                    <Typography>USA, New York - 1060 Str. First Avenue 1</Typography>
                                </Box>
                                <Box>
                                    <Typography variant="h6">CALL CENTER</Typography>
                                    <Typography>24/7 Support</Typography>
                                    <Typography>1800 567 8990 - 1800 345 454</Typography>
                                </Box>
                                <Box>
                                    <Typography variant="h6">EMAIL US</Typography>
                                    <Typography>thememove@healsoul.com</Typography>
                                    <Typography>themesun@healsoul.com</Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Full Name"
                                        placeholder="e.g., John Doe"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Your Email"
                                        placeholder="name@company.com"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Your Phone"
                                        placeholder="Fill number"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="I'm interested in"
                                        placeholder="Insurance Info"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Your Message"
                                        placeholder="Text here"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="primary" fullWidth>
                                        Send Message
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>

    )
}

export default Contact