import React, { useState, useEffect } from 'react'
import { Routes, Route } from "react-router-dom"
import { Slide } from "react-awesome-reveal";
import Header from './compoments/Header'
import Footer from './compoments/Footer'
import Home from './pages/Home'
import About from './pages/About'
import Career from './pages/Career'
import Contact from './pages/Contact'
import PhotoGallery from './pages/PhotoGallery'
import VideoGallery from './pages/VideoGallery'
import Faq from './pages/Faq'
import Blog from './pages/Blog'
import WorkWith from './pages/WorkWith'
import PageNotFound from './compoments/PageNotFound'
import Loading from './compoments/Loading'
import Banner from './compoments/Banner';
import WhatWeDo from './pages/WhatWeDo';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HowToApply from './compoments/HowToApply';




const routes = [
  { path: "/", element: <Home /> },
  { path: "/about", element: <About /> },
  { path: "/blog", element: <Blog /> },
  { path: "/faq", element: <Faq /> },
  { path: "/photos", element: <PhotoGallery /> },
  { path: "/videos", element: <VideoGallery /> },
  { path: "/careers", element: <Career /> },
  { path: "/contact", element: <Contact /> },
  { path: "/howto", element: <HowToApply /> },
  { path: "/whatwedo", element: <WhatWeDo /> },

]

const App = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <Slide triggerOnce direction='down'>
          <Loading />
        </Slide>
      ) : (
        <>
          <Header />
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
          <Footer />
        </>
      )}
    </>


  )
}

export default App